import BlockContent from "@sanity/block-content-to-react";
import React from "react";

export const Project = ({ project, className }) => {
	const { _rawBody } = project;

	return (
		<div
			className={className}
			css={`
				margin-bottom: 16px;
			`}
		>
			<span>
				<b>{project.title}</b> (
				<i>
					{project.technologies
						.map(({ technology }) => technology)
						.join(", ")}
				</i>
				)
			</span>
			<div
				css={`
					& p {
						margin: 0;
					}
				`}
			>
				{_rawBody.map((blocks) => (
					<BlockContent key={blocks._key} blocks={blocks} />
				))}
			</div>
		</div>
	);
};
