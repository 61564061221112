import React from "react";

export const About = ({ isResume }) => (
	<section
		css={`
			margin-bottom: 24px;
		`}
	>
		<h1
			css={`
				line-height: 1;
			`}
		>
			andy gallagher
		</h1>

		<div
			css={`
				max-width: 400px;
				margin: 0 0 1rem;
			`}
		>
			<i>i'm a software engineer from philadelphia.</i>
		</div>
		<h4>
			(<a href="mailto:andy@aboltc.com?subject=hi andy">email</a>) (
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://github.com/andyGallagher"
			>
				github
			</a>
			) (
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://www.linkedin.com/in/%E2%9A%A1%E2%98%94%E2%9B%88/"
			>
				linkedin
			</a>
			)
			{isResume && (
				<>
					{" "}
					(
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://andy-gallagher.com/"
					>
						portfolio
					</a>
					)
				</>
			)}
		</h4>

		<div>
			<p>
				i'm a software engineer at{" "}
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://palolo.com/"
				>
					palolo
				</a>
				, a fintech startup. i'm interested in making websites, personal
				finance, and fixing cars.
			</p>
		</div>
	</section>
);
